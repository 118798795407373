import Swal from "sweetalert2";
import Swiper from "swiper";
import { Scrollbar, Autoplay, Navigation, Pagination, Thumbs} from 'swiper/modules';
import lozad from 'lozad';
import { Fancybox } from "@fancyapps/ui";
import '@fancyapps/ui/dist/fancybox/fancybox.css';
// import './animation'

( function( $ ) {
    $( document ).ready(function() {

        const observer = lozad();
        observer.observe();

        Fancybox.bind("[data-fancybox]");

        $('.navbar_toggler').on('click',function (){
            $('.header__nav').addClass('active')
            $('body').addClass('hidden_body');
            $('.js_nav_open').show();
        })

        $('.header__nav_close').on('click',function (){
            $('.header__nav').removeClass('active')
            $('body').removeClass('hidden_body');
            $('.js_nav_open').hide();
        })

        $(".js_nav_open").on('click',function (){
            $('body').removeClass('hidden_body');
            $(this).hide();
            $('.header__nav').removeClass('active');
        });

        $('.header__nav a').on('click',function (){
            $('.header__nav').removeClass('active')
            $('body').removeClass('hidden_body');
            $('.js_nav_open').hide();
        })

        $('.consultation_popup_open').on("click", function (e) {
            e.preventDefault();
            Swal.fire({
                html: `
                        <div class="swal_inner">
                            <p class="h4 title">Enter your personal information and we will <span>contact you shortly</span></p>
                            <form action="" class="form" id="swal_popup1">
                                <div class="form__fildset">
                                    <input class="form__input" type="text" placeholder="Your name">
                                </div>
                                <div class="form__fildset">
                                    <input class="form__input" type="email" placeholder="Your email">
                                </div>
                                <div class="form__fildset">
                                    <input class="form__input" type="tel" placeholder="Your phone">
                                </div>
                                <button type="submit" class="button popup1_submit_js">Send a request</button>
                            </form>
                        </div>
                      `,
                showCloseButton: true,
                showConfirmButton: false,
                showCancelButton: false,
            })

            $('#swal_popup1').on('submit', function (e) {
                e.preventDefault();
                console.log('popup submit');
                thankPopup();
            });

        });


        function thankPopup() {
            Swal.fire({
                html: `
                        <div class="swal_inner">
                            <p class="h4 title"><span>Thank you</span><br>for your request!</p>
                            <p class="text-center">Our manager will contact you shortly.</p>
                        </div>
                      `,
                showCloseButton: true,
                showConfirmButton: false,
                showCancelButton: false,
            })
        }

        const servicesSwiperSelector = document.querySelector('.services__swiper');
        const servicesSwiper = new Swiper(servicesSwiperSelector, {
            modules: [Navigation],
            slidesPerView: 1,
            spaceBetween: 12,
            loop: true,
            navigation: {
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
            },

            breakpoints: {
                700: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                992: {
                    slidesPerView: 3,
                    spaceBetween: 20
                }
            }
        });

        const testimonialsSwiperSelector = document.querySelector('.testimonials__swiper');
        const testimonialsSwiper = new Swiper(testimonialsSwiperSelector, {
            modules: [Navigation],
            slidesPerView: 1,
            spaceBetween: 12,
            navigation: {
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
            },

            breakpoints: {
                768: {
                    slidesPerView: 2,
                    spaceBetween: 20
                },
                1200: {
                    slidesPerView: 3,
                    spaceBetween: 20
                }
            }
        });

         function swiperProjectGalleryDisplay() {

            if ($('.project-gallery__slider').length) {
                let projectGallerySlider = new Swiper(".project-gallery__slider", {
                    modules: [Pagination],
                    slidesPerView: 1,
                    spaceBetween: 10,
                    pagination: {
                        el: ".swiper-pagination",
                    },
                });

                let windowWidth = window.screen.width;
                if (windowWidth > 768) {
                    projectGallerySlider.destroy()
                }
            }
        }

        //first init
        swiperProjectGalleryDisplay()
        //etc

        //alternative resize
        function resizeHandler() {
            swiperProjectGalleryDisplay()
            //etc
        }

        window.addEventListener("resize", resizeThrottler, false);
        let resizeTimeout;

        function resizeThrottler() {
            if (!resizeTimeout) {
                resizeTimeout = setTimeout(function () {
                    resizeTimeout = null;
                    resizeHandler();
                }, 250);
            }
        }




        $('.portfolio-case__img').mousemove(function(e) {

            $('.portfolio-case__hover_btn').addClass('active');
            $('.portfolio-case__hover_btn').offset({
                left: e.pageX - 40,
                top: e.pageY - 40
            });
        });
        
         $('.portfolio-case__img').mouseleave(function(e) {
            console.log( "mouseleave"  );
            $('.portfolio-case__hover_btn').removeClass('active');


         });
         


});
}( jQuery ) );